import React from "react";
import "./AboutMe.scss";
import { useEffect } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FullWidthImageSection from "../../templates/FullWidthImageSection/FullWidthImageSection";
import TabletListSection from "../../templates/TabletListSection/TabletListSection";
import Banner from "../../components/Banner/Banner";
import PanelSection from "../../templates/PanelSection/PanelSection";
import Hero from "../../templates/Hero/Hero";
import Faq from "../../templates/Faq/Faq";

import XiaohoImage from "../../assets/images/xiaoho_images.png";
import ZeldaImage from "../../assets/images/zelda-game.png";
import SonyGif from "../../assets/gifs/about1.gif";

import PanelData from "../../data/about-sony.json";
import WorkData from "../../data/work-history.json";
import FaqData from "../../data/about-me-faq.json";

function AboutMe() {
        useEffect(() => {
            window.scrollTo(0, 0); 
        }, []);

    return (
        <div className="page-layout">
            <Header />
            <div className="page-layout__main">

                <Hero
                    isStack={true}
                    allowClick={false}
                    header={<>
                        Heya, hello, and welcome! <span className="wave-emoji">👋</span>
                    </>} 
                    body="Glad you’re interested in getting to know me! I’m Sony Chen, a product designer and a self-proclaimed bubble tea master."
                    image={SonyGif}
                    alt="What's this pokemon? It's Sony!!"
                />

                <PanelSection panels={PanelData} />

                <Banner
                    header="My current game list"
                    body="We don’t always have to talk about work, let’s geek out over games! 🎮🔥"
                    gameName="The Legend of Zelda: Echoes of Wisdom"
                    colour="dark"
                    image={ZeldaImage}
                    alt="The Legend of Zelda: Echoes of Wisdom game image"
                    showSectionLabel={false}
                    showGame={true}
                />

                <TabletListSection
                    datas={WorkData}
                    header="My work experiences"
                    body="Preview of my work history, a history of me"
                />

                <FullWidthImageSection
                    header="Nothing just want you to see my dogo"
                    body="Meet my dog, Xiaoho (小賀) the Doge Coin! Her name means super brave in Taiwanese slang. PetSmart even calls her 'Sweet like an angel.' Oh, and she’s 9!"
                    image={XiaohoImage}
                />

                <Faq 
                    header="Sony FAQs" 
                    body="Want to learn more about me? Here’s we go!" 
                    faqData={FaqData}
                />

                <Footer />
            </div>
        </div>
    );
}

export default AboutMe;