import "./Lighthouse.scss";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Header/Header";
import ProjectHero from "../../../../templates/ProjectHero/ProjectHero";
import ContentBlock from "../../../../templates/ContentBlock/ContentBlock";
import ProjectPanelDisplay from "../../../../templates/ProjectPanelDisplay/ProjectPanelDisplay";

function Lighthouse() {
  return (
    <div className="page-layout">
      <Header />
      <main className="page-layout-main">
        <section className="page-layout-main__project-section">
            <ProjectHero
            tag="Design System"
            header="Lighthouse Design System"
            body="Lighthouse is a scalable design system created to unify product experiences across Capital One Canada. Built during a major rebrand and reorganization, it addressed the need for consistency, accessibility, and cross-functional collaboration. From UI foundations to custom Figma plugins and training, Lighthouse continues to evolve as a key design infrastructure supporting multiple teams."
            company="Capital One"
            role="Product Designer"
            year="2022"
            src="https://res.cloudinary.com/dhifyp04n/image/upload/v1744068293/lighthouse_1_esdgr5.png"
            alt="Lighthouse design system hero image with interface components and brand visuals"
            />

            <ContentBlock
            isText
            isImage
            isList={false}
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744069851/Frame_183548_zdgomp.png"
            alt="Preview of the initial rebrand draft from the creative team"
            header="Context"
            body="When I joined Capital One Canada, the company was undergoing both a rebrand and a reorganization. I was brought in during this transition and tasked with leading the major UI rebrand. While the team had been relying on graphic designers to produce UI mockups aligned with the new brand identity, the results lacked consistency, structure, and scalability. There was no componentized system, no reusable templates, and minimal collaboration between designers and developers. The visual hierarchy also created usability issues across key pages. By my second day, I had already started addressing these gaps."
            />

            <ContentBlock
            isText
            isImage={false}
            isList={false}
            header="Challenge"
            body="Another challenge we faced was the limited icon set, which had been created by an external agency. Requesting a single new icon could take up to three months, which was not ideal. Additionally, there was no established color palette. The creative team emphasized the importance of maintaining brand consistency using the four primary brand colors, which we were expected to adopt and build from."
            />

            <ProjectPanelDisplay
            header="Inherited Brand Colors"
            body="The brand was tied to four primary colors, centered around a dark blue tint, which was also used for text. However, the CTA buttons used a separate green that we were required to keep, as both colors originated from the Capital One US guidelines."
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744071359/Frame_183549_nqzobk.png"
            alt="Preview of the color palette provided by the creative team"
            isReverse
            />

            <ProjectPanelDisplay
            header="Simplified Color System"
            body="To improve scalability and reduce complexity in the color system, I proposed merging the two blue tints into one consistent scheme for both text and links. This preserved visual hierarchy between actionable and non-actionable elements while aligning with Capital One US brand guidelines."
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744071228/Frame_183550_fynii1.png"
            alt="Completed color scheme for Lighthouse"
            />

            <ContentBlock
            isText={false}
            isImage
            isList={false}
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744071522/lighthouse_4_nfzcdc.png"
            alt="Screenshot of the Lighthouse design system components with accessibility considerations"
            />

            <ContentBlock
            isText
            isImage
            isList={false}
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744071521/lighthouse_5_txngtn.png"
            header="Building a Scalable and Accessible Design System"
            body="We worked closely with development teams to establish the foundation of Lighthouse, ensuring it could scale across consumer-facing teams. All components met accessibility standards based on Capital One US guidelines, covering contrast ratios, keyboard navigation, screen readers, states, tab order, and more."
            alt="Screenshot of the Lighthouse design system components with accessibility considerations"
            />

            <ContentBlock
            isText
            isImage
            isList={false}
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744072154/lighthouse_6_cck4nw.png"
            header="Comprehensive Documentation"
            body="We created extensive documentation to support onboarding and cross-team consistency. The documentation detailed design principles, component usage, states, code examples, accessibility guidance, theming, and responsive behavior. It served as a reliable reference for both designers and developers."
            alt="Overview of the Lighthouse design system documentation interface showing component guidelines and code snippets"
            />

            <ContentBlock
            isText
            isImage
            isList={false}
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744072549/lighthouse_7_r10dxi.png"
            header="Design Resources for Teams"
            body="Beyond the core UI kit, we built additional Lighthouse resources to support design work across the company. These included 3D brand-aligned assets, accessibility annotation tools, sticky notes, and layout helpers. Since most plugins were blocked at Capital One, we provided foundational elements like grids, spacing, and aspect ratios to maintain consistency and efficiency."
            alt="Preview of Lighthouse design resources including 3D visuals, toolkits, and layout foundations"
            />

            <ContentBlock
            isText
            isImage={false}
            isList={false}
            header="Continuous Evolution"
            body="After launch, I continued growing Lighthouse with new components, tools, and design enhancements. I explored motion guidelines, scalable theming, and expanded the system to support new teams and use cases across the organization."
            />

            <ProjectPanelDisplay
            header="3D Card Artworks"
            body="To enhance card components, I created a set of 3D-rendered artworks aligned with the Lighthouse brand. These visuals were reused across marketing and product, supporting storytelling and brand consistency."
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744072158/lighthouse_2_ahywiq.png"
            alt="3D-rendered card artworks designed for use in Lighthouse card components"
            isReverse
            />

            <ProjectPanelDisplay
            header="3D Icons for Mobile"
            body="For mobile app templates, I designed a custom set of 3D icons that balanced clarity and visual richness at small sizes, bringing a tactile look and feel to the experience."
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744072153/lighthouse_3_phkyyl.png"
            alt="Set of 3D icons created for Lighthouse mobile app design system templates"
            />

            <ProjectPanelDisplay
            header="Figma Plugins"
            body="To streamline workflows, I built internal Figma plugins that enabled one-click setup of templates, aspect ratio presets, file covers, page structures, and handoff tools. These plugins simplified setup and reduced friction for designers."
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744073657/lighthouse_3_fzgzuz.png"
            alt="Lighthouse Figma plugins for fast file setup, aspect ratio management, and handoff tools"
            isReverse
            />

            <ContentBlock
            isText
            isImage
            isList={false}
            image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744072155/lighthouse_8_aqxe1t.png"
            header="Figma Training and Advocacy"
            body="I led training sessions to help the team improve their Figma skills and adopt Lighthouse effectively. Topics ranged from basic usage and Auto Layout to advanced components, Dev Mode, and prototyping. The goal was to create shared understanding and elevate system thinking across teams."
            alt="Screenshot from Lighthouse Figma training session covering components, prototyping, and Dev Mode"
            />

            <ContentBlock
            isText
            isImage={false}
            isList={false}
            header="Outcome"
            bodies={[
                {
                subheader: "Figma adoption and team growth 🪄",
                body: "I led Lighthouse training sessions and weekly office hours. Fifteen designers joined the sessions, covering everything from Figma basics to advanced prototyping. Feedback was positive and helped foster a strong component-based mindset."
                },
                {
                subheader: "Creative team onboarding 🎨",
                body: "The creative team invited us to help build their Figma library for web banners. I created 76 modular components with flexible options for layout, colors, and content. This improved consistency and accelerated delivery."
                },
                {
                subheader: "Engineering collaboration ⚙️",
                body: "I supported engineers during the Lighthouse migration, answering questions, reviewing components, and assisting with the PLV and UAT process. Our efforts contributed to a 1 percent lift in QK Submit."
                }
            ]}
            />
        </ section>
        <Footer noBackgroundColor="true" />
      </main>
    </div>
  );
}

export default Lighthouse;
