import './Chip.scss';
import PropTypes from 'prop-types';

function Chip({ label, icon, onClick, isActive }) {
  return (
    <button 
      type="button" 
      className={`chip ${isActive ? 'chip--active' : ''}`}
      onClick={onClick}
    >
      <i className={`chip__icon fa-regular ${icon}`}></i>
      <span className="chip__label">{label}</span>
    </button>
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

export default Chip;