import "./Redyy.scss";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Header/Header";
import ProjectHero from "../../../../templates/ProjectHero/ProjectHero";
import ContentBlock from "../../../../templates/ContentBlock/ContentBlock";
import FullWidthImageSection from "../../../../templates/FullWidthImageSection/FullWidthImageSection"
import ProjectPanelDisplay from "../../../../templates/ProjectPanelDisplay/ProjectPanelDisplay";

import heroImage from "../../../../assets/images/projects/redyy/redyy_hero.png";
import redyyFull from "../../../../assets/images/projects/redyy/redyy_full_width.png"
import redyyPreview1 from "../../../../assets/images/projects/redyy/redyy_1.png"
import redyyPreview2 from "../../../../assets/images/projects/redyy/redyy_2.png"
import redyyPreview3 from "../../../../assets/images/projects/redyy/redyy_3.png"
import redyyPreview4 from "../../../../assets/images/projects/redyy/redyy_4.png"

function Redyy(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">
                <section className="page-layout-main__project-section">
                    <ProjectHero 
                        tag="Playground"
                        header="Redyy – a smarter way to build your resume"
                        body="Redyy helps you get job-ready with ease. Built as the final capstone project of a 9-month Software Engineering bootcamp at BrainStation, it offers prebuilt templates and AI-powered features to help you create a resume that’s polished, professional, and perfectly tailored for your job hunt."
                        company="Self"
                        role="Full-Stack Product Designer"
                        year="2024"
                        src={heroImage}
                        alt="A preview of the Helcim's email campaign system"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={false}
                        header="Opportunity"
                        image={redyyPreview1}
                        alt="Graph showing that the majority of participants in a quantitative study feel neutral or negative about resume building"
                        body={`This project started with a simple request: “Sony, can you help me create my resume?” As designers, it’s a question we hear often. I saw the capstone as the perfect opportunity to turn that request into something more — a tool not just for the bootcamp, but for real use beyond it.\n\nDuring research, most people described the resume-building process as either neutral or negative. While a few felt positively about it, quantitative data showed that the majority are simply looking for a faster, easier way to tackle this often frustrating task.`}
                    />

                    <ContentBlock
                    isText={true}
                    isImage={false}
                    isList={false}
                    header="But how?"
                    body={
                        <>
                        The application includes several key sections. On the dashboard, users can view relevant job postings and access a list of resumes they've created and saved. When creating a new resume, users can choose from a selection of prebuilt templates to get started quickly.
                        <br /><br />
                        Redyy is built with React using fully reusable components, and the backend uses MySQL with cloud-based storage. It’s a complete and working application. You can try it out at{' '}
                        <a href="https://www.redyy.io" target="_blank" rel="noopener noreferrer">redyy.io</a>.
                        </>
                    }
                    />

                    <FullWidthImageSection 
                        image={redyyFull}
                        variant="image-only"
                        alt="User flow of the Redyy application"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={true}
                        header="More about this project"
                        body="Redyy is a full-stack product design project that began from concept and research through branding, wireframing, and development. It’s a milestone project that helped me grow my software engineering skills throughout the process."
                        image={redyyPreview2}
                        alt="Preview of the Redyy project interface"
                    />

                    <ProjectPanelDisplay
                    header="Establishing the visual language"
                    body="The color scheme was carefully crafted to reflect Redyy’s approachable yet professional tone. Each color was selected to support accessibility and maintain a cohesive brand experience across the platform."
                    image={redyyPreview3}
                    alt="Color palette and visual style used in the Redyy platform"
                    isReverse={true}
                    />

                    <ProjectPanelDisplay
                    header="Bringing the brand to life"
                    body="This animated logo introduces Redyy’s visual identity with energy and clarity. Inspired by the slogan 'Get ready, get hired,' the motion reinforces the brand’s mission to help users feel confident and prepared."
                    video="https://res.cloudinary.com/dhifyp04n/video/upload/v1744008834/Comp_4_liztdt.mp4"
                    isReverse={false}
                    />

                    <ProjectPanelDisplay
                        header="Database structure and functionality"
                        body="Redyy’s database handles user login, resume data, and application tracking using a simple and scalable CRUD structure."
                        image={redyyPreview4}
                        alt="Diagram showing the database structure and user flow of the Redyy platform"
                        isReverse={true}
                    />
                </section>

                <Footer 
                    noBackgroundColor="true"
                />
            </main>
        </div>
    );
};

export default Redyy;