import "./ProjectPanelDisplay.scss";

function ProjectPanelDisplay({
  header,
  body,
  image,
  secondImage,
  alt,
  secondAlt,
  video,  
  isReverse,
  customComponent
}) {
  return (
    <div className="project-panel-display">
      <div
        className={`project-panel-display__section-wrapper ${
          isReverse ? "project-panel-display__section-wrapper--reverse" : ""
        }`}
      >
        <div className="project-panel-display__text-wrapper">
          <h2 className="project-panel-display__text-wrapper--header">{header}</h2>
          <p className="project-panel-display__text-wrapper--body">{body}</p>
        </div>

        {customComponent ? (
          <div className="project-panel-display__component">{customComponent}</div>
        ) : video ? (
          <video
            className="project-panel-display__video"
            controls
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : secondImage ? (
          <div className="project-panel-display__image-pair">
            <img
              className="project-panel-display__image"
              alt={alt}
              src={image}
            />
            <img
              className="project-panel-display__image"
              alt={secondAlt}
              src={secondImage}
            />
          </div>
        ) : (
          <img
            className="project-panel-display__image"
            alt={alt}
            src={image}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectPanelDisplay;