import React, { useState } from "react";
import './Gallery.scss';
import Header from "../../components/Header/Header";
import TitleContentBlock from "../../components/TitleContentBlock/TitleContentBlock";
import workGallery from "../../data/work-action-cards.json";
import ActionCardsSection from "../../templates/ActionCardsSection/ActionCardsSection";
import Footer from "../../components/Footer/Footer";
import PasswordGate from "../../templates/PasswordGate/PasswordGate";
import Chip from "../../components/Chip/Chip";

function Gallery() {
  const [selectedCategory, setSelectedCategory] = useState("work");

  const chips = [
    { label: "Work", icon: "fa-suitcase", value: "work" },
    { label: "Side project", icon: "fa-face-awesome", value: "side-project" }
  ];

  const filteredGallery = workGallery.filter(item => item.category === selectedCategory);

  return (
    <div className="page-layout">
      <Header />
      <main className="page-layout-main">
        <div className="page-layout-main__wrapper">
          <TitleContentBlock 
            header="Featured " 
            headerUnderline="Work"
            isSparkle={true}
            body="From concept to execution, here are some pieces I’ve brought to life."
          />

          <PasswordGate>
            <div className="page-layout-main__chips">
              {chips.map(chip => (
                <Chip
                  key={chip.value}
                  label={chip.label}
                  icon={chip.icon}
                  isActive={selectedCategory === chip.value}
                  onClick={() => setSelectedCategory(chip.value)}
                />
              ))}
            </div>

            <ActionCardsSection datas={filteredGallery} />
          </PasswordGate>
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default Gallery;