import React from "react";
import CardTile from "../../components/CardTile/CardTile";
import './CardsSection.scss';

function CardsSection({header, body, cards}){
    return(
        <section className="cards-section">
            <div className="cards-section__section-wrapper">
                <div className="cards-section__text-wrapper">
                    <h2 className="cards-section__text-wrapper--header">{header}</h2>
                    <p className="cards-section__text-wrapper--body">{body}</p>
                </div>

                <div className="cards-section__cards-tile-wrapper">
                    {cards.map((card) =>(
                    <CardTile 
                        key={card.id}
                        image={card.image}
                        header={card.title}
                        body={card.body}
                        url={card.url}
                        isExternal={true}
                    />
                    ))}
            </div>
            </div>
        </section>
    );
}

export default CardsSection;