import React from "react";
import './SectionLabel.scss';

function SectionLabel({textRight, textLeft}){
    return(
        <div className="section-label-main-wrapper">
            <div className="section-label-desktop">
                <span className="section-label-desktop__text">{textRight} 
                <span className="section-label-desktop__text--divider"> | </span> 
                {textLeft}</span>
            </div>

            <div className="section-label-mobile">
                <div className="section-label-mobile__wrapper--margin-bottom">
                    <span className="section-label-mobile__text">{textRight}</span>
                </div>

                <div className="section-label-mobile__wrapper">
                    <span className="section-label-mobile__text">{textLeft}</span>
                </div>
            </div>
        </div>
    );
}

export default SectionLabel;