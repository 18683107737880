import './TitleContentBlock.scss';
import stroke from '../../assets/images/stroke.svg';

function TitleContentBlock({ header, headerUnderline, body, isSparkle }) {
    return (
        <div className="title-content-block">
            <div className="title-content-block__title-section">
                {isSparkle &&
                    <span className="title-content-block__sparkle">✨</span>
                }
                <h1 className="title-content-block__title">{header}
                    <span className='title-content-block__highlight'>{headerUnderline}
                        <img src={stroke} alt="underline stroke" className="title-content-block__stroke" />
                    </span>
                </h1>
                {isSparkle &&
                    <span className="title-content-block__sparkle">✨</span>
                }
            </div>
                <p className="title-content-block__body">
                    {body.split('\n\n').map((line, index) => (
                        <span key={index}>
                        {line}
                        <br /><br />
                        </span>
                    ))}
                </p>
        </div>
    );
}

export default TitleContentBlock;