import "./Pdp.scss";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Header/Header";
import ProjectHero from "../../../../templates/ProjectHero/ProjectHero";
import ContentBlock from "../../../../templates/ContentBlock/ContentBlock";
import FullWidthImageSection from "../../../../templates/FullWidthImageSection/FullWidthImageSection";
import ProjectPanelDisplay from "../../../../templates/ProjectPanelDisplay/ProjectPanelDisplay";

function Pdp(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">
                <section className="page-layout-main__project-section">
                    <ProjectHero 
                        tag="Product Redesign"
                        header="PDP Refresh"
                        body="The Capital One Canada product detail page (PDP) had not been updated in a long time and no longer aligned with the current brand direction. While the existing design served its purpose for years, it felt visually disconnected. We took on a complete redesign to bring the PDP back in line with Capital One’s evolving brand and ensure a more cohesive experience."
                        company="Capital One"
                        role="Product Designer"
                        year="2022"
                        src="https://res.cloudinary.com/dhifyp04n/image/upload/v1744259595/Frame_2_dh4nij.png"
                        alt="A preview of the Capital One product detail page"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Context"
                        body={`The Capital One Canada PDP hasn’t been updated since 2017–2018 and no longer reflects the brand. A redesign was attempted in 2022, but it lacked hierarchy — with text, buttons, and links all styled the same — making the page hard to navigate. \n\nWe’re now redesigning it to align with the brand while facing key constraints: the structure must remain the same, the timeline is tight, and the legacy design system is still in place. This project also serves as a stepping stone toward a more modern, component-based system as devs prepare for a system overhaul.`}
                        isVideo={true}
                        videoSrc='https://res.cloudinary.com/dhifyp04n/video/upload/v1744345395/PDP_1_h6ekae.mp4'
                    />

                    <ProjectPanelDisplay
                        header="Content reorganization under constraints"
                        body="Due to business and legal requirements, the content had to remain mostly unchanged. The goal was to modernize the visual design while staying on brand. This created an opportunity to improve clarity, so I worked with a UX content writer to reorganize and better structure the information."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744345762/pdp_1_fmrc5m.png"
                        alt="Preview of the updated PDP layout showing revised content grouping and hierarchy."
                        isReverse={true}
                    />

                    <ProjectPanelDisplay
                        header="Bridging legacy with modern UX"
                        body="The PDP hadn’t been updated since 2017. A previous redesign was done without product design input but approved by the business. I took a balanced approach, designing between the original and the previous version to align with business needs while modernizing the experience and meeting the deadline."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744345762/pdp_2_ngwa93.png"
                        alt="Screenshot of the legacy PDP layout prior to the new redesign effort."
                        isReverse={false}
                    />

                    <ProjectPanelDisplay
                        header="Applying the refreshed brand"
                        body="This preview showcases the updated brand expression, featuring vibrant colors like jade green, canary yellow, powder blue, and sky blue — used frequently across social and marketing channels. The challenge was translating that visual energy into a digital experience that feels fresh, yet refined and accessible."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744345791/Frame_16_ybh97p.png"
                        alt="Screenshots of social post designs highlighting the updated Capital One brand color palette."
                        isReverse={true}
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={false}
                        isVideo={false}
                        header="Early exploration and alignment"
                        body={`We began the redesign process by exploring different visual directions, kicking off multiple critique rounds and stakeholder check-ins. The initial draft was heavily influenced by stakeholder input, but feedback from quantitative research revealed it felt too modern and startup-like, which did not reflect Capital One’s established brand tone.\n\nTo stay aligned while improving usability, we approached the redesign with a component mindset from the beginning. This included stress testing components across different scenarios to ensure they were scalable, reusable, and flexible enough to support future system updates.`}
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744346747/Frame_183487_hrrwvi.png"
                        alt="Preview of the first draft of the redesign"
                    />

                    <ContentBlock 
                        isText={false}
                        isImage={true}
                        isList={false}
                        isVideo={false}
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347153/Frame_21_nsyfxq.png"
                        alt="Preview of the first draft of the redesign"
                    />

                    <ProjectPanelDisplay
                        header="Testing two visual directions"
                        body="We finalized two PDP design variations for testing, one using a colorful brand-forward approach, and one with a more muted visual style. Quantitative research showed the colorful version performed better overall."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347412/Frame_183491_lkq6hz.png"
                        alt="Side-by-side preview of two PDP design variations, one colorful and one muted, used for A/B testing."
                        isReverse={true}
                    />

                    <ProjectPanelDisplay
                        header="Creating custom visuals with 3D"
                        body="With limited creative resources, I created 3D-rendered graphics to speed up the design process while staying on brand. These visuals built on the existing 2D style but used brand colors to add visual energy and reinforce awareness across the product experience."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347411/Frame_183492_hy3hf1.png"
                        alt="Component previews in both colorful and muted versions using 3D-rendered brand graphics."
                        isReverse={false}
                    />
                    
                    <ProjectPanelDisplay
                        header="Component craftsmanship"
                        body="Each component was carefully built in close collaboration with developers to ensure strong structure, accessibility, and SEO performance. Despite time constraints, the team remained focused on getting the details right, from semantic markup to overall usability and performance"
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347411/Frame_183511_qrdqnn.png"
                        alt="Component breakdown preview highlighting structure, accessibility, and SEO integration."
                        isReverse={true}
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        isVideo={false}
                        header="Final design: scaling across card suites"
                        body="The final design went through multiple rounds of review and stakeholder alignment. It was first applied to the Smart Rewards Blue Card for its initial release, then extended to the Gold Card (GHR) with a 50/50 A/B test in market. After seeing a strong positive lift, the design was scaled to 100% and later rolled out to the remaining redesigned pages across card suites."
                    />

                    <FullWidthImageSection 
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347650/Frame_183488_czypri.png"
                        variant="image-only"
                    />

                    <ProjectPanelDisplay
                        header="Establishing a Color System Across Suites"
                        body="Each card suite was given a tailored color palette to strengthen brand recognition and consistency. Backgrounds aligned with card renders, using jade green, sky blue, and canary yellow. Gold and Blue suites mirrored each other, while Silver, as the travel card, featured jade green and powder blue to complement nature-inspired imagery."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744349198/Frame_183490_plol0m.png"
                        alt="Color variations applied across different card suites, showing tailored background and brand color pairings."
                        isReverse={false}
                    />

                    <ProjectPanelDisplay
                        header="Validating the Redesign Through A/B Testing"
                        body="The redesigned PDP was first tested with the Gold Card (GHR) using a 50/50 A/B split. The test resulted in a 2% lift in engagement, marking a significant milestone and validating the direction of the new design before scaling to other card suites."
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347411/Frame_183510_vavhig.png"
                        alt="GHR Gold Card PDP design used in the A/B test, which delivered a 2% lift in performance."
                        isReverse={true}
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={true}
                        isVideo={false}
                        header="Impact and Measurable Results"
                        body={`The redesign helped establish a strong working relationship with the development team and laid the foundation for Lighthouse — Capital One Canada's evolving design system. By taking a component-based approach, the redesign became easier to scale across card suites, reducing implementation time and increasing efficiency. These components have since been reused in other pages like Compare, Marketing, and Product Landing, enabling faster rollout across the ecosystem. \n\nGHR PDP A/B test showed:`}
                        listItems={[
                            "3.3% lift in button click rate (99% statistical significance)",
                            "3.9% lift in app submit rate (90% statistical significance)",
                            "6.9% lift in conversion rate (95% statistical significance)"
                        ]}
                        image="https://res.cloudinary.com/dhifyp04n/image/upload/v1744347413/Frame_183489_zdjzwx.png"
                        alt="Overview of the design system components and performance results from the GHR PDP A/B test."
                    />
                   
                </section>

                <Footer 
                    noBackgroundColor="true"
                />
            </main>
        </div>
    );
};

export default Pdp;