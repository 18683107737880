import './ContentBlock.scss';

function ContentBlock({
  header,
  subheader,
  body,
  bodies = [],
  isText,
  isImage,
  image,
  alt,
  isList,
  listItems = [],
  useXIcon = false,
  isVideo = false,        
  videoSrc = ''           
}) {
  return (
    <section className='content-block'>
      <div className='content-block__section-wrapper'>
        <div className='content-block__content'>
          {isText && (
            <>
              {header && <h2 className='content-block__header'>{header}</h2>}

              {body && (
                <>
                  {subheader && (
                    <h3 className='content-block__subheader'>{subheader}</h3>
                  )}
                  <p className='content-block__body'>{body}</p>
                </>
              )}

              {bodies.length > 0 &&
                bodies.map((item, index) => (
                  <div key={index}>
                    {item.subheader && (
                      <h3 className='content-block__subheader'>{item.subheader}</h3>
                    )}
                    <p className='content-block__body'>{item.body}</p>
                  </div>
                ))}
            </>
          )}

          {isList && listItems.length > 0 && (
            <div className='content-block__custom-list'>
              {listItems.map((item, index) => (
                <div key={index} className='content-block__custom-list-item'>
                  <i
                    className={`fa-duotone ${
                      useXIcon ? 'fa-square-x' : 'fa-square-check'
                    } ${useXIcon ? 'icon-red' : 'icon-green'}`}
                  ></i>
                  <span className='content-block__custom-text'>{item}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        {isVideo && videoSrc && (
          <video
            className='content-block__video'
            src={videoSrc}
            autoPlay
            loop
            muted
            playsInline
          />
        )}

        {isImage && (
          <img className='content-block__image' src={image} alt={alt} />
        )}
      </div>
    </section>
  );
}

export default ContentBlock;