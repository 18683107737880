import "./ActionCardsSection.scss";
import ActionCard from "../../components/ActionCard/ActionCard";

function ActionCardsSection({datas}){
    return(
        <section className="action-cards-section">
            <div className="action-cards-section__section-container">
                {datas.map((data)=>(
                        <ActionCard 
                            className="action-cards-section__card"
                            img={data.image}
                            title={data.title}
                            href={data.link}
                            badgeData={[
                        {
                            isIcon: data.isIcon === "true",  
                            icon: data.logo,
                            label: data.company,
                            alt: data.company
                        },
                        {
                            isIcon: false,
                            icon: "",
                            label: data.label,
                            alt: ""
                        }
                        ]}
                        />
                    ))}
            </div>
        </section>
    );
};

export default ActionCardsSection;