import "./PasswordGate.scss";
import { useState } from "react";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import xFile from "../../assets/images/file.png";

function PasswordGate({ children }) {
  const [input, setInput] = useState("");
  const [isAuthed, setIsAuthed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const isUnlocked = localStorage.getItem("unlockedGallery") === "true";
  const unlockExpiry = parseInt(localStorage.getItem("unlockExpiry"), 10);
  const isExpired = !unlockExpiry || Date.now() > unlockExpiry;

  if ((isAuthed || isUnlocked) && !isExpired) {
    return children;
  }

  const checkPassword = async (password) => {
    try {
      const res = await fetch("https://portfolio-site-gallery-pass-3ebc10b8259d.herokuapp.com/api/check-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ password })
      });

      const result = await res.json();
      return result.success;
    } catch (err) {
      setError("Something went wrong. Please try again.");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const isValid = await checkPassword(input);
    setLoading(false);

    if (isValid) {
      setIsAuthed(true);
      const unlockUntil = Date.now() + 60 * 60 * 1000; // 1 hour
      localStorage.setItem("unlockedGallery", "true");
      localStorage.setItem("unlockExpiry", unlockUntil.toString());
    } else {
      setError("Nahh not this one 🥹");
    }
  };

  return (
    <div className="password-gate">
      <h2 className="password-gate__heading">opps...the x-files</h2>
      <img className="password-gate__image" src={xFile} alt="A 3d render of a file with some papers in it" />

      <form onSubmit={handleSubmit} className="password-gate__form">
        <InputField
          type="password"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            if (error) setError("");
          }}
          placeholder="Password"
          label="Enter the spell"
          error={error}
        />
        <Button 
          isLink={false}
          type="primary"
          text={loading ? "Checking..." : "Submit"}
          buttonSize="56px"
          onClick={() => {}}
          buttonType="submit"
          buttonWidth="full-width"
        />
      </form>
    </div>
  );
}

export default PasswordGate;