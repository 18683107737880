import React from "react";
import './Banner.scss';
import SectionLabel from "../SectionLabel/SectionLabel";
import GameList from "../GameList/GameList";

function Banner({header, body, badgeTextRight, badgeTextLeft, colour, showSectionLabel, showGame,image, gameName, alt}){
    return(
        <section className={`banner banner--${colour}`}>
            <div className="banner__section-wrapper">
                <div className="banner__section-upper">
                    <h2 className="banner__section-upper--header">{header}</h2>
                    <p className="banner__section-upper--body">{body}</p>
                </div>

                <div className="banner__section-bottom">
                    {showSectionLabel && (
                        <SectionLabel 
                        textRight={badgeTextRight} 
                        textLeft={badgeTextLeft} 
                        />
                    )}

                    {showGame && (
                        <GameList 
                        image={image}
                        gameName={gameName}
                        alt={alt}
                    />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Banner;