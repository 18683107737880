import { useState } from "react";
import "./InputField.scss";

const InputField = ({ label, type, placeholder, onChange, value, name, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === "password";

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="input-field">
      <label className="input-field__label">{label}</label>
      <div className="input-field__wrapper">
        <input 
          className={`input-field__input ${error ? "input-field__input--error" : ""}`}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
        />
        {isPassword && (
          <button
            type="button"
            className="input-field__toggle"
            onClick={toggleVisibility}
            aria-label="Toggle password visibility"
          >
            <i className={`input-field__showbnt fa-regular ${showPassword ? "fa-face-glasses" : "fa-face-flushed"}`}></i>
          </button>
        )}
      </div>
      {error && (
        <div className="input-field__error">
          <i className="fa-solid fa-circle-xmark"></i>
          <span className="input-field__error--text">{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputField;