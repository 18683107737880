import { Navigate } from "react-router-dom";

function ProtectedGalleryRoute({ children }) {
  const isUnlocked = localStorage.getItem("unlockedGallery") === "true";
  const unlockExpiry = parseInt(localStorage.getItem("unlockExpiry"), 10);
  const isExpired = !unlockExpiry || Date.now() > unlockExpiry;

  if (!isUnlocked || isExpired) {
    localStorage.removeItem("unlockedGallery");
    localStorage.removeItem("unlockExpiry");
    return <Navigate to="/gallery" />;
  }

  return children;
}

export default ProtectedGalleryRoute;