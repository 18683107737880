import React from 'react';

function FigmaEmbed({ src, className = '' }) {
  return (
    <iframe
      className={`figma-embed ${className}`}
      src={src}
      allowFullScreen
      title="Check out my Figma prototype"
      frameBorder="0"
    ></iframe>
  );
}

export default FigmaEmbed;