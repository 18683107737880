import "./Email.scss";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Header/Header";
import ProjectHero from "../../../../templates/ProjectHero/ProjectHero";
import ContentBlock from "../../../../templates/ContentBlock/ContentBlock";
import FullWidthImageSection from "../../../../templates/FullWidthImageSection/FullWidthImageSection"
import ProjectPanelDisplay from "../../../../templates/ProjectPanelDisplay/ProjectPanelDisplay";

import heroImage from "../../../../assets/images/projects/email_hero.jpg";
import emailFull from "../../../../assets/images/projects/email_full_width.jpg"
import emailPreview1 from "../../../../assets/images/projects/email_1.png"
import emailPreview2 from "../../../../assets/images/projects/email_2.png"
import emailPreview3 from "../../../../assets/images/projects/email_3.png"
import emailPreview4 from "../../../../assets/images/projects/email_4.png"


function Email(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">
                <section className="page-layout-main__project-section">
                    <ProjectHero 
                        tag="Design system"
                        header="Email Campaign System"
                        body="Created for the marketing content team, this system makes it easy to build email campaigns with speed and clarity. Using a library of reusable components and structured templates, the team can create, preview, and launch campaigns with confidence while staying aligned with the brand."
                        company="Helcim"
                        role="Product Designer"
                        year="2021"
                        src={heroImage}
                        alt="A preview of the Helcim's email campaign system"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Problem"
                        body={`Before this project, the email design workflow was fragmented and time-consuming. The content team would ideate and write campaign material, then hand it off to the design team to create visuals like the template and hero image. Once finalized, the mockup was passed to a developer to build the HTML email in code.\n\nThis linear process created bottlenecks, limited collaboration, and made it difficult for the content team to iterate or preview their work. Even simple campaigns required significant time and coordination across teams.`}
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Opportunity"
                        body="Creating a static design system for email templates was a key opportunity to streamline cross-functional workflows. It not only reduced the time and effort required across teams, but also empowered the content team to make visual adjustments on their own — all while maintaining design consistency. In a fast-paced startup environment, minimizing handoffs and enabling self-sufficiency was essential to move quickly without sacrificing quality."
                    />

                    <FullWidthImageSection 
                        image={emailFull}
                        variant="image-only"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={true}
                        header="Result"
                        body="The email design system was built and implemented in both Figma and Unlayer. Each system contains a comprehensive content block library, allowing teams to mock up templates by simply dragging and dropping components — into the auto-layer in Figma or using pre-set templates in Unlayer."
                        listItems={[
                            "Campaign starts with content team ideation.",
                            "Mockups are created in Figma by content specialists.",
                            "Design team reviews and approves the mockup.",
                            "Content team builds final version in Unlayer.",
                            "HTML package is exported for development.",
                            "Campaign is tested and launched."
                        ]}
                    />

                    <ContentBlock 
                        isText={false}
                        isImage={true}
                        isList={false}
                        image={emailPreview1}
                        alt="A collection of email template components"
                    />

                    <ProjectPanelDisplay
                        header="Content block library"
                        body="A comprehensive content block library with a variety of designs that stay consistent with the brand. Every block was created with user testing to ensure usability and clarity."
                        image={emailPreview2}
                        alt="A preview of the email template"
                        isReverse={true}
                    />

                    <ContentBlock 
                        isText={false}
                        isImage={true}
                        isList={false}
                        image={emailPreview3}
                        alt="A collection of email template components"
                    />

                    <ContentBlock 
                        isText={false}
                        isImage={true}
                        isList={false}
                        image={emailPreview4}
                        alt="A collection of email template components"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Outcome"
                        bodies={[
                            {
                            subheader: "The workflow became smoother — but there’s more room to grow.",
                            body: "Before fully rolling out the new system, I prepared a foundational toolkit for the content team: a content block library, an email design system 101 deck, and an Unlayer tutorial presentation. I also made sure they had access to the Figma library and component guides, so everyone could share the same vision. It took some time for the team to get used to the new process, but the shift was absolutely worth it. Email templates can now be built much faster than before."
                            },
                            {
                            subheader: "Copy is always longer than you think…",
                            body: "In the beginning, I often had to compromise design due to long email content. These lengthy blocks would sometimes break the layout and throw off the visual balance. I knew it was tough for the content team to cut down their messaging — every word mattered to them, especially when communicating with merchants. But gradually, once they saw that a strong hero visual could clearly express the same message, they felt more confident trimming down their copy."
                            },
                            {
                            subheader: "It’s faster now — but imagine if we had this…",
                            body: "The email system is now fully adopted by the marketing department and is being used effectively by the content and dev teams. However, the design team still has to manually create all hero images. Some campaigns require five to seven versions, which is time-consuming — especially since I’m still the one designing them while approving the templates. What would take this system even further is a visual asset library — something that lets designers or content managers quickly generate hero images using modular elements, without having to start from scratch every time."
                            }
                        ]}
                    />
                </section>

                <Footer 
                    noBackgroundColor="true"
                />
            </main>
        </div>
    );
};

export default Email;