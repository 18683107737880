import React, { useState, useEffect } from "react";
import "./Header.scss";
import IconLink from "../IconLink/IconLink";
import TextLink from "../TextLink/TextLink";
import Logo from "../Logo/Logo";
import { useLocation } from "react-router-dom";

function Header() {
    const location = useLocation();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        if (isDrawerOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [isDrawerOpen]);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);  
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const openNav = () => {
        setDrawerOpen(true);
        console.log("open");
    };

    const closeNav = () => {
        setDrawerOpen(false);
        console.log("close");
    };

    return (
        <header>
            <div className="nav-desktop">
                <div className="nav-desktop__logo-wrapper">
                    <Logo />
                </div>

                <div className="nav-desktop__nav-container">
                    <nav className="nav-desktop__nav-wrapper">
                        <TextLink text="Gallery" url="/gallery"/>
                        <TextLink text="Archive" url="https://www.sony-chen-temp.com/personal-projects" />
                        <TextLink text="Blog" url="https://www.sony-chen-temp.com/blog" />
                        <TextLink 
                            text="About me" 
                            url="/about_me"
                            isActive={location.pathname === "/about_me"}
                        />
                        <TextLink text="Contact" url="https://www.sony-chen-temp.com/contact" />
                    </nav>
                </div>

                <div className="nav-desktop__social-container">
                    <nav className="nav-desktop__social-wrapper">
                        <IconLink isTooltip={true} isLink={true} tooltipText="Let's connect!"
                            icon="fa-brands fa-linkedin fa-lg" url="https://www.linkedin.com/in/sony-chen-48433b135/" />
                        <IconLink isTooltip={true} isLink={true} tooltipText="I code too!"
                        icon="fa-brands fa-github fa-lg" url="https://github.com/sonni0101" />
                        <IconLink isTooltip={true} isLink={true} tooltipText="I make plugins!"
                            icon="fa-brands fa-figma fa-lg" url="https://www.figma.com/@sonychen" />
                        <IconLink isTooltip={true} isLink={true} tooltipText="Have some fun!"
                            icon="fa-solid fa-slot-machine fa-lg" url="https://www.sony-chen-temp.com/fortune-cookie" />
                    </nav>
                </div>
            </div>

            <div className={`nav-mobile ${isScrolled ? "scrolled" : ""} ${isDrawerOpen ? "open" : "close"}`}>
                <div className="nav-mobile__nav-wrapper">
                    <Logo />
                    <IconLink icon="fa-solid fa-burger fa-lg" isLink={false} onClick={openNav} />
                </div>

                <div className={`nav-mobile-drawer ${isDrawerOpen ? "open" : "close"}`}>
                    <div className="nav-mobile-drawer__logo-wrapper">
                        <IconLink icon="fa-solid fa-xmark fa-lg" isLink={false} onClick={closeNav} />
                    </div>

                    <div className="nav-mobile-drawer__nav-container">
                        <nav className="nav-mobile-drawer__nav-wrapper">
                            <TextLink text="Gallery" url="/gallery" />
                            <TextLink text="Archive" url="https://www.sony-chen-temp.com/personal-projects" />
                            <TextLink text="Blog" url="https://www.sony-chen-temp.com/blog" />
                            <TextLink text="About me" url="/about_me" />
                            <TextLink text="Contact" url="https://www.sony-chen-temp.com/contact" />
                        </nav>
                    </div>

                    <div className="nav-mobile-drawer__social-container">
                        <nav className="nav-mobile-drawer__social-wrapper">
                            <IconLink isLink={true} icon="fa-brands fa-linkedin fa-lg" url="https://www.linkedin.com/in/sony-chen-48433b135/" />
                            <IconLink isLink={true} icon="fa-brands fa-github fa-lg" url="https://github.com/sonni0101" />
                            <IconLink isLink={true} icon="fa-brands fa-figma fa-lg" url="https://www.figma.com/@sonychen" />
                            <IconLink isLink={true} icon="fa-solid fa-slot-machine fa-lg" url="https://www.sony-chen-temp.com/fortune-cookie" />
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;