import "./Button.scss";
import { Link } from "react-router-dom";

const Button = ({
  type = "primary",
  isLink,
  text,
  buttonWidth,
  to,
  onClick,
  target,
  rel,
  buttonType = "button",
}) => {
  const buttonClass = `button button--${type} button__${buttonWidth}`;
  const textClass = "button__text";

  return isLink ? (
    <Link className={buttonClass} to={to} target={target} rel={rel}>
      <p className={textClass}>{text}</p>
    </Link>
  ) : (
    <button className={buttonClass} onClick={onClick} type={buttonType}>
      <p className={textClass}>{text}</p>
    </button>
  );
};

export default Button;