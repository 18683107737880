import { useEffect, useState } from "react";
import "./ProjectHero.scss";

function ProjectHero({ tag, header, body, company, role, year, src, alt }) {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);  
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <section className={`project-hero ${scrolled ? "scrolled" : ""}`}>
            <div className="project-hero__section-wrapper">
                <div className="project-hero__info-card">
                    <img className="project-hero__image" src={src} alt={alt} />

                    <div className="project-hero__content-group">
                        <div className="project-hero__header">
                            <h1 className="project-hero__label">{tag}</h1>
                            <h2>{header}</h2>
                        </div>
                        <p className="project-hero__body">{body}</p>
                    </div>

                    <div className="project-hero__detail-table">
                        <div className="project-hero__table-item">
                            <h3 className="project-hero__table-header">Company</h3>
                            <p className="project-hero__table-body">{company}</p>
                        </div>
                        <div className="project-hero__table-item">
                            <h3 className="project-hero__table-header">Role</h3>
                            <p className="project-hero__table-body">{role}</p>
                        </div>
                        <div className="project-hero__table-item">
                            <h3 className="project-hero__table-header">Year</h3>
                            <p className="project-hero__table-body">{year}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectHero;