import React, { useRef, useState } from "react";
import "./AchExample.scss";
import BankSvg4 from "../../assets/icon/ach/bank_symbol-3.svg";
import BankSvg5 from "../../assets/icon/ach/bank_symbol-4.svg";
import BankSvg6 from "../../assets/icon/ach/bank_symbol-5.svg";
import BankSvg7 from "../../assets/icon/ach/bank_symbol-6.svg";

function AchExample() {
  const [bankId, setBankId] = useState("");
  const [transitId, setTransitId] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [focusedField, setFocusedField] = useState("");

  const instiRef = useRef();
  const transitRef = useRef();
  const accountRef = useRef();

  const handleBankIdChange = (e) => {
    const val = e.target.value.replace(/\D/g, "").slice(0, 3);
    setBankId(val);
  };

  const handleTransitIdChange = (e) => {
    const val = e.target.value.replace(/\D/g, "").slice(0, 5);
    setTransitId(val);
  };

  const handleAccountIdChange = (e) => {
    const val = e.target.value.replace(/\D/g, "").slice(0, 7);
    setBankAccountId(val);
  };

  const accountFirst2 = bankAccountId.slice(0, 2);
  const accountLast5 = bankAccountId.slice(2, 7);

  return (
    <div className="ach-example">
      <div className="ach-example__title-group">
        <h2 className="ach-example__title">Banking Information</h2>
        <hr className="ach-example__title-divider" />
      </div>

      <div className="ach-example__cheque-box">

        <div
          className={`ach-example__cheque-group ${focusedField === "transit" ? "active" : ""}`}
          onMouseEnter={() => transitRef.current?.focus()}
          onClick={() => transitRef.current?.focus()}
        >
          <span className="ach-example__cheque-box-number">{transitId || "00000"}</span>
          <span className="ach-example__cheque-box-label">Transit #</span>
        </div>

        <img className="ach-example__cheque-symbol ach-example__cheque-symbol--alone" src={BankSvg5} alt="" aria-hidden="true" />

        <div
          className={`ach-example__cheque-group ${focusedField === "bank" ? "active" : ""}`}
          onMouseEnter={() => instiRef.current?.focus()}
          onClick={() => instiRef.current?.focus()}
        >
          <span className="ach-example__cheque-box-number">{bankId || "000"}</span>
          <span className="ach-example__cheque-box-label">Bank ID</span>
        </div>

        <img className="ach-example__cheque-symbol ach-example__cheque-symbol--alone" src={BankSvg4} alt="" aria-hidden="true" />

        <div
          className={`ach-example__cheque-group ${focusedField === "bank-account" ? "active" : ""}`}
          onMouseEnter={() => accountRef.current?.focus()}
          onClick={() => accountRef.current?.focus()}
        >
          <div className="ach-example__cheque-inner-group">
            <span className="ach-example__cheque-box-number">{accountFirst2 || "00"}</span>
            <img className="ach-example__cheque-symbol" src={BankSvg6} alt="" aria-hidden="true" />
            <span
              className="ach-example__cheque-box-number"
              id="bankAccountRestId"
            >
              {accountLast5 || "00000"}
            </span>
          </div>

          <div className="ach-example__inner-group-divider">
            <hr className="ach-example__cheque-box-divider" />
            <span className="ach-example__cheque-box-label">Account #</span>
            <hr className="ach-example__cheque-box-divider" />
          </div>
        </div>

        <img className="ach-example__cheque-symbol ach-example__cheque-symbol--alone" src={BankSvg7} alt="" aria-hidden="true" />
      </div>

      <form className="ach-example__form">
        <div className="ach-example__input-container">
          <label className="ach-example__label" htmlFor="act_number">
            Bank Account Number:
          </label>
          <br />
          <input
            ref={accountRef}
            className={`ach-example__input-field ${focusedField === "bank-account" ? "active" : ""}`}
            type="text"
            id="act_number"
            name="act_number"
            value={bankAccountId}
            onChange={handleAccountIdChange}
            onFocus={() => setFocusedField("bank-account")}
            onBlur={() => setFocusedField("")}
          />
          <br />
        </div>

        <div className="ach-example__input-group">
          <div className="ach-example__input-container">
            <label className="ach-example__label" htmlFor="insti_number">
              Financial Institution Number:
            </label>
            <br />
            <input
              ref={instiRef}
              className={`ach-example__input-field ${focusedField === "bank" ? "active" : ""}`}
              type="text"
              id="insti_number"
              name="insti_number"
              value={bankId}
              onChange={handleBankIdChange}
              onFocus={() => setFocusedField("bank")}
              onBlur={() => setFocusedField("")}
            />
            <br />
          </div>

          <div className="ach-example__input-container">
            <label className="ach-example__label" htmlFor="transit_number">
              Branch/Transit Number:
            </label>
            <br />
            <input
              ref={transitRef}
              className={`ach-example__input-field ${focusedField === "transit" ? "active" : ""}`}
              type="text"
              id="transit_number"
              name="transit_number"
              value={transitId}
              onChange={handleTransitIdChange}
              onFocus={() => setFocusedField("transit")}
              onBlur={() => setFocusedField("")}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AchExample;