import React from "react";
import { useEffect } from "react";
import './Home.scss';
import Header from "../../components/Header/Header";
import Hero from "../../templates/Hero/Hero";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import CardsSection from "../../templates/CardsSection/CardsSection";
import DrageableElm from "../../components/DragableElm/DragableElm";
import cardsData from "../../data/work-gallery.json"
import XiaohoImage from "../../assets/images/xiaoho_images.png";
import SonyImage from "../../assets/images/sony_in_frame.png";
import CardsComposition from "../../templates/CardsComposition/CardsComposition";
import DragableElmData from "../../data/dragable-elm.json"
import SkillData from "../../data/skill-set.json";
import Ticker from "../../templates/Ticker/Ticker";
import BannerPromo from "../../components/BannerPromo/BannerPromo";
import PluginImage from "../../assets/images/plugin-image.png"
import Button from "../../components/Button/Button";

function Home(){

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return(
        <div className="page-layout">
            <Header />
            <div className="page-layout__main">
                <div className="drag">
                    <DrageableElm />
                </div>
                <main>
                    <Hero 
                    isStack={false}
                    image={SonyImage}
                    allowClick={false}
                    alt="Sony’s cartoon-style portrait with short hair, wearing a black Nike t-shirt."
                    header="Product Designer, multi-disciplinary or a magician✨"
                    body="I’m Sony Chen, a senior product designer with a background in industrial design. I’m also a bubble tea enthusiast. My favorite ratio is black tea with milk, 30% sugar, and less ice."
                    />

                    <Banner 
                    header="Clean UI + Efficient UX + magic = Sony" 
                    body="My co-worker calls me “Super Sony” because I’m a fast learner with a touch of OCD (except when it comes to my room), always striving to achieve my projects to perfection. And of course, I run on bubble tea!" 
                    badgeTextRight="Currently @ Capital One" 
                    badgeTextLeft="Formerly @ Voyager"
                    colour="blue"
                    showSectionLabel={true}
                    />

                    <CardsComposition
                        cardData={SkillData}
                        isIcon={true}
                        isTitle={true}
                    />

                    <CardsSection 
                    header="Work galley"
                    body="I’m migrating my site to a React library. During this process, you might see a mix of old and new elements. For portfolio details, please visit these sections."
                    cards={cardsData}  
                    />

                    <BannerPromo 
                    image={PluginImage}
                    header="Check out my Figma plugin"
                    body="Whenever I have time or a spark of inspiration, I’ll kick back on the couch and code plugins—with my dog as my little coding assistant."
                    isButton={true}
                    isLink={true}
                    type="ghost"
                    text="Go to Figma community"
                    to="https://www.figma.com/@sonychen"
                    buttonSize= "default"
                    target="_blank"
                    />

                    <Ticker 
                    header="Work I’ve contributed to"
                    isBody={false}
                    />
                </main>

                <Footer />
            </div>
        </div>
    );
};

export default Home;