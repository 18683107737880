import './Badge.scss';

function Badge({isIcon, icon, label, alt}) {
    return (
        <div className='badge'>
            {isIcon &&
                <img className="badge__icon" src={icon} alt={alt}/>
            }
            <h1 className="badge__label">{label}</h1>
        </div>
    );
}

export default Badge;