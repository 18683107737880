import './ActionCard.scss';
import Badge from '../Badge/Badge';

function ActionCard({ img, title, href, isIcon, icon, label, alt, className, badgeData }) {
    return (
        <a className={`action-card ${className}`} href={href} target="_blank" rel="noopener noreferrer">
            <div className='action-card__image-wrapper'>
                <img src={img} alt={title} className="action-card__image" />
            </div>

            <div className='action-card__content'>
                <div className='action-card__badge-group'>

                    {
                        badgeData.map((data, index)=>(
                            <Badge 
                                key={index}
                                isIcon={data.isIcon}
                                icon={data.icon}
                                label={data.label}
                                alt={data.alt}
                            />
                        ))
                    }
                </div>
                <h2 className="action-card__title">{title}</h2>
            </div>
        </a>
    );
}


export default ActionCard;