import React, { useState } from "react";
import "./PanelDisplay.scss";

function PanelDisplay({ header, body, image, alt, isReverse, allowClick, secondImage, hideTitle = false }) {
    const [currentImage, setCurrentImage] = useState(image);
    const [isFading, setIsFading] = useState(false);

    const handleImageClick = () => {
        if (!allowClick) return;

        setIsFading(true);
        setTimeout(() => {
            setCurrentImage((prevImage) => (prevImage === image ? secondImage : image));
            setIsFading(false);
        }, 300);
    };

    return (
        <div className={`panel-display ${isReverse ? "panel-display--reverse" : ""}`}>
            <div className="panel-display__text-wrapper">
                {!hideTitle && (
                    <h2 className="panel-display__text-wrapper--header">{header}</h2>
                )}
                <p className="panel-display__text-wrapper--body">{body}</p>
            </div>

            <img
                className={`panel-display__image ${isFading ? "fade" : ""}`}
                alt={alt}
                src={currentImage}
                onClick={handleImageClick}
                style={{ cursor: allowClick ? "pointer" : "default" }}
            />
        </div>
    );
}

export default PanelDisplay;